import Vuex from "vuex";
import Vue from "vue";
import frontModule from '@/parts/Front/store'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        front: frontModule
    }
})

export default store;
