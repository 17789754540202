import findUrl from "@/libraries/links/LinkResolver";

export default class notification {
  constructor(notification) {
    for (let [index, value] of Object.entries(notification)) {
      this[index] = value;
    }
    this.type = this.getType();
    if (typeof this.data === "string") {
      this.data = JSON.parse(this.data);
    }
  }

  // اون چیزی که نشون میدیم و از این میگیریم
  /**
   * @return {{
   *   id: String,
   *   to: String,
   *   description: String,
   *   read: Boolean
   * }}
   */
  getNotification() {
    let notif = null;
    switch (this.type) {
      case "public":
        notif = {
          title: "بندیتو",
          to: "/",
          description: this.data.description,
        };
        break;
      case "SendProductAvailableNotification":
        notif = {
          title: "موجودی جدید",
          to: "/product/" + this.data.product_id,
          description: this.data.description,
        };
        break;
      case "order":
        notif = {
          title: "سفارش " + this.data.order_id,
          description: this.data.description,
          to: "/order/" + this.data.order_id,
        };
        break;
      case "InvoicePaid":
        notif = {
          title: "سفارش " + this.data.tracking_code + " با موفقیت پرداخت شد",
          description: this.data.description,
          to: "/order/" + this.data.tracking_code,
        };
        break;
      default:
        notif = {
          title: this.data.title,
          description: this.data.description,
          to: findUrl(
            this.data.linkable_type,
            this.data.linkable_id,
            this.data.slug,
            this.data.link
          ),
        };
    }
    notif = {
      id: this.id,
      read: Boolean(this.read_at),
      created_at: this.created_at,
      is_notified:this.is_notified,
      ...notif,
    };

    return notif;
  }

  /** گرفتن نوع نوتیفیکیشن
   * Types: SendProductAvailableNotification
   */
  getType() {
    switch (true) {
      case this.type.includes("SendProductAvailableNotification"):
        return "SendProductAvailableNotification";
      case this.type.includes("InvoicePaid"):
        return "InvoicePaid";
      default:
        return this.type;
    }
  }
}
