const routes = [{
        path: '/',
        component: () =>
            import (/* webpackChunkName: "home" */ '../pages/Index'),
        meta: {
            title: 'صفحه اصلی'
        },
        children: [{
                path: '/',
                name: 'home',
                component: () =>
                    import (/* webpackChunkName: "home" */ '../pages/Home'),
                meta: {
                    title: 'صفحه اصلی'
                },
            },
            {
                path: '/club-panel',
                component: () =>
                    import (/* webpackChunkName: "home" */ '../pages/CustomerClub'),
                meta: {
                    title: 'باشگاه مشتریان'
                },
                name: 'club',
                children:[
                    {
                        path: '/club-panel',
                        component: () =>
                            import (/* webpackChunkName: "home" */ '../components/customerClub/Dashboard.vue'),
                        meta: {
                            title: 'داشبورد باشگاه'
                        },
                        name: 'ClubDashboard'
                    },
                    {
                        path: '/club-exchange',
                        component: () =>
                            import (/* webpackChunkName: "home" */ '../components/customerClub/Exchange.vue'),
                        meta: {
                            title: 'تبدیل بن باشگاه'
                        },
                        name: 'ClubExchange'
                    }, 
                    {
                        path: '/club-after-before',
                        component: () =>
                            import (/* webpackChunkName: "home" */ '../components/customerClub/AfterBefore.vue'),
                        meta: {
                            title: 'تصویر قبل و بعد'
                        },
                        name: 'ClubAfterBefore'
                    },
                    {
                        path: '/club-scores',
                        component: () =>
                            import (/* webpackChunkName: "home" */ '../components/customerClub/Scores.vue'),
                        meta: {
                            title: 'لیست امتیازات'
                        },
                        name: 'ClubScores'
                    },
                    {
                        path: '/club-bon-requests',
                        component: () =>
                            import (/* webpackChunkName: "home" */ '../components/customerClub/BonRequest.vue'),
                        meta: {
                            title: 'لیست درخواست های بن'
                        },
                        name: 'BonRequest'
                    },
                    {
                        path: '/club-missons',
                        component: () =>
                            import (/* webpackChunkName: "home" */ '../components/customerClub/Missions.vue'),
                        meta: {
                            title: 'ماموریت های باشگاه'
                        },
                        name: 'clubMissions'
                    },
                ]
            },
            // {
            //     path: '/club',
            //     component: () =>
            //         import (/* webpackChunkName: "home" */ '../pages/club'),
            //     meta: {
            //         title: 'باشگاه مشتریان'
            //     },
            //     name: 'club',
            //     children:[
            //         {
            //             path: '/',
            //             component: () =>
            //                 import (/* webpackChunkName: "home" */ '../pages/clubHome'),
            //             meta: {
            //                 title: 'باشگاه مشتریان'
            //             },
            //             name: 'ClubHome'
            //         },
            //         {
            //             path: 'awards',
            //             component: () =>
            //                 import (/* webpackChunkName: "home" */ '../pages/clubAwards'),
            //             meta: {
            //                 title: 'جوایز'
            //             },
            //             name: 'clubAwards'
            //         },
                    
            //         {
            //             path: 'score-history',
            //             component: () =>
            //                 import (/* webpackChunkName: "home" */ '../pages/clubScoreHistory'),
            //             meta: {
            //                 title: 'جوایز'
            //             },
            //             name: 'scoreHistory'
            //         },
                    
            //         {
            //             path: 'lottery',
            //             component: () =>
            //                 import (/* webpackChunkName: "home" */ '../pages/clubLottery'),
            //             meta: {
            //                 title: 'جوایز'
            //             },
            //             name: 'lottery'
            //         },
                    
            //         {
            //             path: 'missions',
            //             component: () =>
            //                 import (/* webpackChunkName: "home" */ '../pages/clubMissions'),
            //             meta: {
            //                 title: 'جوایز'
            //             },
            //             name: 'missions'
            //         },
            //     ]
            // },
            {
                path: '/club-landing',
                component: () =>
                    import (/* webpackChunkName: "home" */ '../pages/clubLanding'),
                meta: {
                    title: 'باشگاه مشتریان'
                },
                name: 'clubLanding'
            },
            {
                path: '/product/:id/:slug?',
                component: () =>
                    import (/* webpackChunkName: "home" */ '../pages/ProductDetails'),
                meta: {
                    title: 'محصول'
                },
                name: 'product.detail'
            },
            {
                path: '/products',
                name: 'products',
                component: () =>
                    import (/* webpackChunkName: "home" */ '../pages/ProductList'),
                meta: {
                    title: 'لیست محصولات'
                },
            },
            {
                path: '/club-detail',
                name: 'ClubDetail',
                component: () =>
                    import (/* webpackChunkName: "home" */ '../pages/ClubDetail'),
                meta: {
                    title: 'لیست محصولات'
                },
            },
            {
                path: '/products/category/:category/:slug?',
                name: 'product.category',
                component: () =>
                    import (/* webpackChunkName: "home" */ '../pages/ProductList'),
                meta: {
                    title: 'لیست محصولات'
                },
            },
            {
                name: 'weblog.list',
                path: '/weblog-list',
                component: () =>
                    import (/* webpackChunkName: "front" */ '../pages/WeblogList'),
                meta: {
                    title: 'پست ها'
                },
                props: true

            },
            {
                name: 'questionLandign',
                path: '/question-landing/:id',
                component: () =>
                    import (/* webpackChunkName: "front" */ '../pages/questionFlash'),
                meta: {
                    title:'پرسش نامه'
                },
                props: true

            },
            {
                name: 'weblogList',
                path: '/weblog-list/:id/:slug?',
                component: () =>
                    import (/* webpackChunkName: "front" */ '../pages/WeblogList'),
                meta: {
                    title: 'پست ها'
                },
                props: true

            },
            {
                path: '/weblog-details/:id/:slug?',
                name: 'blogDetail',
                component: () =>
                    import (/* webpackChunkName: "front" */ '../pages/WeblogDetails'),
                meta: {
                    title: 'جزئیات پست'
                },
                props: true
            },
            {
                path: '/cart',
                component: () =>
                    import (/* webpackChunkName: "front" */ '../pages/Cart'),
                meta: {
                    title: 'سبد خرید'
                },
                name: 'cart'
            },
            {
                path: '/user-panel',
                name: 'panel',
                component: () =>
                    import (/* webpackChunkName: "front" */ '../pages/UserPanel'),
                meta: {
                    title: 'پنل کاربری',
                    auth: true
                },
            },


            {
                path: '/order/:id',
                name: 'order',
                component: () =>
                    import (/* webpackChunkName: "front" */ '../pages/order'),
                meta: {
                    title: 'سفارشات',
                    auth: true
                },
            },
            {
                path: '/callback',
                name: 'callback',
                component: () =>
                    import (/* webpackChunkName: "front" */ '../pages/callback'),
                meta: {
                    title: 'در حال هدایت'
                },
            }, ,
            {
                path: '/about-us',
                name: 'about-us',
                component: () =>
                    import (/* webpackChunkName: "front" */ '../pages/AboutUs'),
                meta: {
                    title: 'درباره ما'
                },
            },
            {
                path: '/contact',
                name: 'contact',
                component: () =>
                    import (/* webpackChunkName: "front" */ '../pages/Contact'),
                meta: {
                    title: 'ارتباط با ما'
                },
            },
            {
                path: '/common-questions',
                component: () =>
                    import (/* webpackChunkName: "front" */ '../pages/CommonQuestions'),
                meta: {
                    title: 'پرسش های متداول'
                },
            },
            {
                path: '/about',
                component: () =>
                    import (/* webpackChunkName: "front" */ '../pages/About.vue'),
                meta: {
                    title: 'درباره '
                },
            },
            {
                name: 'page',
                path: '/pages/:page/:slug?',
                component: () =>
                    import (/* webpackChunkName: "front" */ '../pages/pages.vue'),
                meta: {
                    title: 'صفحات '
                },
            },
            {
                path: '/compare',
                name: 'compare',
                component: () => import('@/parts/Front/pages/Compare')
            },
            {
                path: '/404',
                name: '404',
                component: () => import('@/parts/Front/pages/404')
            },
            {
                path: '/post-tracking',
                name: 'post-tracking',
                component: () => import('@/parts/Front/pages/PostTracking')
            },
            {
                name: 'AllCategoties',
                path: '/all-categories',
                component: () =>
                    import (/* webpackChunkName: "front" */ '../pages/AllCategories.vue'),
                meta: {
                    title: 'دسته بندی '
                },
            },
            {
                name: 'faq',
                path: '/faq',
                component: () =>
                    import (/* webpackChunkName: "front" */ '../pages/Faq.vue'),
                meta: {
                    title: 'سوالات متداول'
                },
            }
        ]
    },
    {
        path: '/register',
        name: 'register',
        component: () =>
            import (/* webpackChunkName: "front" */ '../pages/LoginAndRegister'),
        meta: {
            title: 'عضویت'
        },
    },
    { path: '*', beforeEnter: (to, from, next) => { next('/404') } },

]



export default routes
