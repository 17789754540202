import axios from "axios";

const state = {
  clubData: {},
  clubStatus: false,
  scoreList: [],
  bonRequests: [],
  missions: [],
  topTen: {},
};
const getters = {
  getClubData(state) {
    return state.clubData;
  },
  getClubStatus(state) {
    return state.clubStatus;
  },
  getClubScores(state) {
    return state.scoreList;
  },
  getClubBonRequests(state) {
    return state.bonRequests;
  },
  getClubMissions(state) {
    return state.missions;
  },
  getClubTopTen(state) {
    return state.topTen;
  },
};
const mutations = {
  setClubData(state, data) {
    state.clubData = data;
  },
  setClubScores(state, list) {
    state.scoreList = list;
  },
  setClubStatus(state, status) {
    state.clubStatus = status;
  },
  setClubBonRequests(state, list) {
    state.bonRequests = list;
  },
  setClubMissions(state, list) {
    state.missions = list;
  },
  setClubTopTen(state, list) {
    state.topTen = list;
  },
};
const actions = {
  async getClubDataFromServer({ commit }) {
    commit("setClubStatus", true);
    await axios
      .get("customer/customers_club/get_club_data")
      .then((response) => {
        commit("setClubData", response.data.data.data);
        commit("setClubStatus", false);
      })
      .catch((error) => {
        this.$root.notify(error);
      });
  },
  async getClubScoresFromServer({ commit }, page) {
    commit("setClubStatus", true);
    await axios
      .get(`customer/customers_club/get_club_score_list?page=${page}`)
      .then((response) => {
        commit("setClubScores", response.data.data.scores);
        commit("setClubStatus", false);
      })
      .catch((error) => {
        this.$root.notify(error);
      });
  },
  async getClubBonRequestsFromServer({ commit }) {
    commit("setClubStatus", true);
    await axios
      .get("customer/customers_club/get_bon_convert_request_list")
      .then((response) => {
        commit("setClubBonRequests", response.data.data.list);
        commit("setClubStatus", false);
      })
      .catch((error) => {
        this.$root.notify(error);
      });
  },
  async getClubMissionsFromServer({ commit }) {
    await axios
      .get("customer/customers_club/get_missions")
      .then((res) => {
        commit("setClubMissions", res.data.data.missions);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getClubTopTenFromServer({ commit }) {
    await axios
      .get("customers_club/get_top_ten")
      .then((res) => {
        commit("setClubTopTen", res.data.data.top_ten);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
