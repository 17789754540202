const state = {
  extraShippingWithAddItem: {},
  discountCoupen:null,
  changeCartInfo:false,
  hasExtraWeight:false,
};

const getters = {
  getHasExtraWeight(state){
    return state.hasExtraWeight
  },
  getChangeCartInfo(state){
    return state.changeCartInfo
  },
  getDiscountCoupen(state){
    return state.discountCoupen
  },
  getExtraShippingWithAddItem(state) {
    return state.extraShippingWithAddItem;
  },
};

const mutations = {
  setHasExtraWeight(state,status){
    state.hasExtraWeight=status
  },
  setChangeCartInfo(state,status){
    state.changeCartInfo=status;
  },
  setDiscountCoupen(state,discount){
    state.discountCoupen=discount
  },
  setExtraShippingWithAddItem(state, data) {
    state.extraShippingWithAddItem[data.address_id] = data.shipping_price;
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
