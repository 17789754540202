import axios from "axios";
import * as vueMethods from "../../../../libraries/VueMethods";
import cart from "@/parts/Front/components/cart/cart";
import notification from "@/parts/Front/components/notification/classes/notification";
const state = {
  homeData: null,
  homeDataStatus: false,
  currentCartPriority: -1,
  currentCart: null,
  // آرایه از توابع که بعد از لود شدن هوم اجرا میشن
  onHomeDataRetrieved: [],
  indexSliders: null,
  customerNotfication:null,
  oldRequestedProduct: [],
  validCustomers:[],
  beforeAfterList:[],
};
const getters = {
 
  getSliderIndex(state) {
    return state.indexSliders;
  },
  getHomeData(state) {
    //دریافت اطلاعات برای صفحه اصلی
    return state.homeData;
  },
  isAuthenticated(state) {
    //آیا لاگین کرده
    return state.homeData;
  },
  getProductSuggestions(state) {
    return state.productSuggestions;
  },

  getHomeDataStatus(state) {
    return state.homeDataStatus;
  },

  // گرفتن سایز های موجود
  getSizeValues(state) {
    return state.homeData ? state.homeData.size_values : null;
  },
  getColors(state) {
    return state.homeData ? state.homeData.colors : null;
  },
  getUserFavorite(state) {
    let homeData = state.homeData;
    if (!homeData) {
      return null;
    }
    let user = homeData.user.user;
    if (!user) {
      return null;
    }
    return user.favorites;
  },
  getFullName(state) {
    let homeData = state.homeData;
    if (!homeData) {
      return null;
    }
    let user = homeData.user.user;
    if (!user) {
      return null;
    }
    if (user.full_name) {
      return user.full_name;
    }
    return user.mobile;
  },

  getNotifications(state) {
    return state.customerNotfication 
    ? {
        items: state.customerNotfication.items.map(
          (item) => new notification(item)
        ),
        totalUnread: state.customerNotfication.total_unread,
      }
    : null;
    
  },
  getRealNotifications() {
    return state.homeData && state.homeData.user.notifications
      ? state.homeData.user.notifications
      : null;
  },
  getValidCustomers(state){
    return state.validCustomers
  },
  getBeforeAfter(state) {
    return state.beforeAfterList;
  },
};
const mutations = {
  setNotification(state,list){
    state.customerNotfication=list;
  },
  setIndexSlider(state, list) {
    state.indexSliders = list;
  },
  changeOldRequestList(state, newValue) {
    let lastValue = state.oldRequestedProduct;
    lastValue.push(newValue);
    state.oldRequestedProduct = lastValue;
  },
  setHomeDataStatus(state, status) {
    //ست کردن استاسوس اطلاعات صفحه اصلی
    state.homeDataStatus = status;
  },
  setHomeData(state, data) {
    //ست کردن اطلاعات صفحه اصلی
    if (data.colors) {
      data.colors = vueMethods.addLabel(data.colors);
    }
    state.homeData = data;
  },
  // اضاضه کردن یک کالبک فانکشن با واقعه لود شدن خانه
  // این تابع بعد لود شدن خانه اجرا میشه
  addHomeRetrievedEvent(state, callback) {
    if (state.homeData) {
      callback(state.homeData);
    } else {
      state.onHomeDataRetrieved.push(callback);
    }
  },
  setUserCart(state, payLoad) {
    let cart = payLoad.cart;
    let priority = payLoad.priority || 0;
    if (priority >= state.currentCartPriority) {
      state.currentCartPriority = priority;
      state.currentCart = cart;
    }
    if (state.homeData) {
      state.homeData.user.cart = state.currentCart;
    }
  },
  addUserFavorite(state, product) {
    let homeData = state.homeData;
    if (!homeData) {
      return null;
    }
    let user = homeData.user.user;
    if (!user) {
      return null;
    }
    state.homeData.user.user.favorites.push(product);
  },
  deleteUserFavorite(state, id) {
    state.homeData.user.user.favorites = state.homeData.user.user.favorites.filter(
      (item) => item.id != id
    );
  },
  newAddress(state, address) {
    state.homeData.user.user.addresses.push(address);
  },
  editAddress(state, address) {
    state.homeData.user.user.addresses = state.homeData.user.user.addresses.map(
      (item, index) => {
        if (item.id == address.id) {
          return address;
        }

        return item;
      }
    );
  },
  deleteAddress(state, id) {
    state.homeData.user.user.addresses = state.homeData.user.user.addresses.filter(
      (item) => item.id != id
    );
  },
  loadMoreNotifications(state, newNotifications) {
    state.homeData.user.notifications.items = [
      ...state.homeData.user.notifications.items,
      ...newNotifications,
    ];
  },
  setValidCustomers(state, list) {
    state.validCustomers = list
  },
  setBeforeAfter(state, list) {
    state.beforeAfterList = list
  },
};
const actions = {
  async getNotficationsFromServer({commit}){
    await axios.get('customer/notifications').then((res)=>{
      commit('setNotification',res.data.data.notifications)
    })
  },
    async getIndexPageContent({ commit, state }, key) {
        // من ریدم توی ای پی آی ها که هیچکدوم یک شکل نیستن اینجوری بچه هم میتونه با دیتابیس کار کنه
        let apiKey = {
          "new-products": "new_products",
          "most-sales": "mostSales",
          "most-discounts": "mostDiscount",
          "suggestions": "suggestions",
          "most-timed-discounts": "mostTimedDiscount",
          "is-package" : "isPackage",
          "most-discounts-new" : 'mostDiscountNew'
        };
        let finded = state.oldRequestedProduct.find((request) => {
          if (request.key == key) {
            return request;
          }
        });
        if (finded) {
          return finded.value;
        } else {
          let res = await axios.get(`front/${key}`);
          commit("changeOldRequestList", {
            key: key,
            value: res.data.data.response[apiKey[key]],
          });
          return res.data.data.response[apiKey[key]];
        }
      },
  async getIndexSlider({ state, commit }) {
    let finded = state.oldRequestedProduct.find((request) => {
      if (request.key == "sliders") {
        return request;
      }
    });
    if (finded) {
      commit("setIndexSlider", finded.value);
    } else {
      let res = await axios.get("front/sliders");
      commit("setIndexSlider", res.data.data.response);
      commit("changeOldRequestList", { key: "sliders", value: res.data.data.response });
    }
  },
  async getHomeDataFromServer({ commit, state }, payLoad) {
    //گرفتن اطلاعات مورد نیاز در صفحه اصلی
    let $root = payLoad.$root;
    // در صورت لاگین بودن کاربر کوکی ست میشود و اطلاعات کاربری نیز دریافت میشود
    if (vueMethods.getCookie("Authorization")) {
      axios.defaults.headers.common["Authorization"] = vueMethods.getCookie(
        "Authorization"
      );
    }
    commit("setHomeDataStatus", true);
    await axios
      .get("/front/home", {
        params: { ...cart.getCartRequestQueryString() },
      })
      .then((response) => {
        const data = response.data.data.response;
        let settingGroups = {};
        data.settings.forEach((setting) => {
          if (!settingGroups[setting.group]) {
            settingGroups[setting.group] = {};
          }
          settingGroups[setting.group][setting.name] = setting.value;
        });
        data.settings = settingGroups;
        commit("setHomeData", data);
        commit("setHomeDataStatus", false);
        if (data.user.user) {
          commit("setLoginStatus", data.user);
        } else {
          commit("setLoginStatus", false);
          let initialCarts = cart.getInitialCartForCookieMode(
            data.cart_request
          );
          commit("setUserCart", {
            priority: 0,
            cart: new cart(this._vm, initialCarts, "cookie"),
          });
        }
        $root.onPushTokenReceived((newPushToken) => {
          if (data.user.user && newPushToken != data.user.device_token) {
            axios.post("customer/device-token", {
              device_token: newPushToken,
            });
          }
        });
        // اجرا کردن توابعی که منتظر لود شدن خانه هستن
        state.onHomeDataRetrieved.forEach((func) => {
          func(data);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async addToCart({ state }, payLoad) {
    let variety = payLoad.variety;
    let product = payLoad.product;
    let quantity = payLoad.quantity;
    let cart = state.homeData.user.cart;
    if (cart.mode == "server") {
      const response = await axios.post(`customer/cart/add/${variety.id}`, {
        quantity: quantity,
      });
      let cartItem = response.data.data.cart;
      cart.addToCart(cartItem);
      return response;
    } else {
      // It may throw an error with message "NO_STOCK"
      state.homeData.user.cart.addFakeChart(variety, quantity, product);
      return {
        data: {
          message: "محصول با موفقیت به سبد خرید اضافه شد",
        },
      };
    }
  },
  async incrementCart({ state, dispatch }, cartItem) {
    return dispatch("changeCartQuantity", {
      cartItem: cartItem,
      newQuantity: cartItem.quantity + 1,
    });
  },
  async decrementCart({ state, dispatch }, cartItem) {
    return dispatch("changeCartQuantity", {
      cartItem: cartItem,
      newQuantity: cartItem.quantity - 1,
    });
  },
  async changeCartQuantity({ state }, { cartItem, newQuantity }) {
    let message =
      newQuantity > cartItem.quantity
        ? "محصول با موفقیت به سبد خرید شما اضافه شد"
        : "محصول با موفقیت از سبد خرید کم شد";
    if (newQuantity == 0) {
      throw new Error("ZERO");
    } else if (newQuantity > cartItem.variety.quantity) {
      throw new Error("NO_STOCK");
    }
    let cart = state.homeData.user.cart;
    let response;
    if (cart.mode === "server") {
      response = await this._vm.$axios.post(`customer/cart/${cartItem.id}`, {
        quantity: newQuantity,
        _method: "put",
      });
    } else {
      response = {
        data: {
          message: message,
        },
      };
    }
    cart.setCartQuantity(cartItem, newQuantity);

    return response;
  },
  async deleteCart({ state }, cartItem) {
    let cart = state.homeData.user.cart;
    if (cart.mode == "server") {
      const response = await axios.delete(`customer/cart/${cartItem.id}`);
      cart.deleteCart(cartItem);
      return response;
    } else {
      cart.deleteCart(cartItem);
      return {
        data: {
          message: "محصول با موفقیت از سبد خرید حذف شد",
        },
      };
    }
  },
  async getValidCustomerFromServer({commit}){
    await axios.get('front/valid-customers').then((res)=>{
      commit('setValidCustomers',res.data.data.customers)
    })
  },
  async getBeforeAfterFromServer({commit}) {
    await axios.post('front/home/get_before_after_images').then((res)=>{
      commit('setBeforeAfter' , res.data.data)
    }).catch((error)=>{
      this.$root.notify(error);
    })
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
