import axios from "axios";
import cloneDeep from "lodash/cloneDeep";

const state = {
  productsAll: null,
  productsStatus: false,
  productsMaxPrice: 50000,
  searchResult: null,
  searchStatus: false,
  oldRequestedProduct: [],
  savedProductInfo: null,
  selectedVariety: null,
};

const getters = {
  getSavedProductInfo(state) {
    return state.savedProductInfo;
  },
  getSelectedVaritey(state) {
    return state.selectedVariety;
  },
  getProductsAll(state) {
    return state.productsAll;
  },
  getProductsStatus(state) {
    return state.productsStatus;
  },
  getProductsMaxPrice(state) {
    return state.productsMaxPrice;
  },
  // دریافت نتیج جستوجو
  getSearchResult(state) {
    return state.searchResult;
  },
  // دریافت استاتوس نتیج جستوجو
  getSearchStatus(state) {
    return state.searchStatus;
  },
};

const mutations = {
    changeOldRequestList(state, newValue) {
        let lastValue = state.oldRequestedProduct;
        lastValue.push(newValue);
        state.oldRequestedProduct = lastValue;
      },
  setProductLinkedInfo(state, product) {
    state.savedProductInfo = product;
  },
  setSelectedVariety(state, variety) {
    state.selectedVariety = variety;
  },
  setProductsAll(state, products) {
    state.productsAll = products;
  },
  setProductsStatus(state, status) {
    state.productsStatus = status;
  },
  setProductsMaxPrice(state, price) {
    state.productsMaxPrice = price;
  },
  // ست کردن نتایج جستوجو
  setSearchResult(state, list) {
    state.searchResult = list;
  },
  setSearchStatus(state, status) {
    state.searchStatus = status;
  },
};

const actions = {
    async getProductFromServer({ commit, state }, id) {
        let finded = state.oldRequestedProduct.find((request) => {
          if (request.key == JSON.stringify({ id: id })) {
            return request;
          }
        });
        if (finded) {
          return finded.value;
        } else {
          let res = await axios.get(`front/products/${id}`);
          commit("changeOldRequestList", {
            key: JSON.stringify({ id: id }),
            value: res,
          });
          return res;
        }
      },
  // اکشن جستوجو در میان محصولات
  async searchInProducts({ commit }, search) {
    commit("setSearchStatus", true);
    await axios.get("/front/products/search?q=" + search).then((response) => {
      commit("setSearchStatus", false);
      commit("setSearchResult", response.data.data.products);
    });
  },
  async getProductsDataFromServer({ commit }, payLoad) {
    let query = cloneDeep(payLoad.query);
    delete query.selected_sizes;
    query.attribute_value_id = payLoad.selectedAttributeValueIds;

    commit("setProductsStatus", true);
    try {
      const response = await axios.get("front/products", { params: query });
      commit("setProductsAll", response.data.data.products);
      setTimeout(() => {
        document.documentElement.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
      if (response.data.data.priceFilter.max_price > 50000) {
        let max =
          Math.ceil(response.data.data.priceFilter.max_price / 1000) * 1000;
        commit("setProductsMaxPrice", max);
      }
      commit("setProductsStatus", false);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
