export default {
    // baseUrl: "https://api-atlas.cheshbaste.com/v1",
    // baseUrl: "http://192.168.43.18:8000/v1",
    // baseUrl: 'http://192.168.43.18:8000/v1',
    // baseUrl: 'http://172.20.10.4:8000/v1',
    // baseUrl: 'https://api.atlasmode.ir/v1',
    // baseUrl: 'https://api.neekshop.net/v1',
    baseUrl: 'https://api.benedito.ir/v1',
    // baseUrl: 'http://127.0.0.1:8000/v1',
    // baseUrl: 'https://api-dev.atlasmode.ir/v1',
    title: 'فروشگاه بندیتو',
    colorLoading: 'var(--color-theme)',
    firebase: {
        apiKey: "AIzaSyBKLagOM8uWxORAK_1JVykWdHIPcajUGOQ",
        authDomain: "atlasmode-9947b.firebaseapp.com",
        projectId: "atlasmode-9947b",
        storageBucket: "atlasmode-9947b.appspot.com",
        messagingSenderId: "279425365371",
        appId: "1:279425365371:web:3ff0abce89aabd6c91b9de",
        vapidKey: 'BC6jA4vEEjWPwX06htCNTdNVdleCw4qR6jPwMGAvCFb_rZTQ0jvygsXjkb0JddoeUMXOkgCLma8ZWOa32v64pi8'
    }
}
